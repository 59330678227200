import request from '@/utils/request'

// 查询客户佣金记录
export function findAgentCommissionStatistics(params) {
  return request({
    url: `/agent_commission_statistics`,
    method: 'get',
    params: params
  })
}

// 查询客户佣金记录详情
export function findAgentCommissionStatistic(id, params) {
  return request({
    url: `/agent_commission_statistics/${id}`,
    method: 'get',
    params: params
  })
}

// 导出佣金记录
export function exportAgentCommissionStatistics(data) {
  return request({
    url: `/agent_commission_statistics/export`,
    method: 'post',
    data
  })
}
