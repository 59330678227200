<template>
  <top-agent-self-commission-statistic v-if="this.$store.getters.userRole === 'top_agent'" />
  <merchant-self-commission-statistic v-else-if="this.$store.getters.userRole === 'merchant'" />
</template>

<script>
import TopAgentSelfCommissionStatistic from '@/views/commission_statistics/top_agent_self/index'
import MerchantSelfCommissionStatistic from '@/views/commission_statistics/agency_self/index'

export default {
  name: 'SideMenu',
  components: {
    TopAgentSelfCommissionStatistic,
    MerchantSelfCommissionStatistic
  }
}
</script>
