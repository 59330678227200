<template>
  <div>
    <a-row class="title">佣金</a-row>
    <a-row type="flex" class="iotplt-margin-bottom-15px">
      <a-col flex="70px">
        <a-icon
          type="transaction"
          :style="{fontSize: '55px', color: 'rgb(95, 166, 248)' }"
        />
      </a-col>

      <a-col flex="200px">
        <a-spin :spinning="loadingCommission">
          <a-statistic
            class="iotplt-statistic"
            title="佣金余额"
            prefix="￥"
            :precision="2"
            :value="commissionAvailable"
          />
        </a-spin>
      </a-col>

      <a-col flex="200px">
        <a-spin :spinning="loadingCommission">
          <a-statistic
            class="iotplt-statistic"
            title="累计佣金"
            prefix="￥"
            :precision="2"
            :value="commissionTotal"
          />
        </a-spin>
      </a-col>

      <a-col
        :span="6"
        v-if="isMerchant"
      >
        <a-button type="primary">
          提现
        </a-button>
      </a-col>
    </a-row>

    <search-agency-commission-statistic @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loadingTable"
      :pagination="false"
      row-key="id"
    >
      <div slot="billable_no" slot-scope="billable_no, record">
        <a @click="showInfoModal(record)">{{ billable_no }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchTableData"
    />

    <show-commission-statistic
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="this.showingId"
      :agent-id="this.agentId"
      :billable-type="this.showingBillableType"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchAgencyCommissionStatistic from '@/views/commission_statistics/agency_self/Search'
import { findAgentCommissionStatistics, exportAgentCommissionStatistics } from '@/api/commission_statistic'
import ShowCommissionStatistic from '@/views/commission_statistics/agency_self/info'
import { findAgentFinanceOverview } from '@/api/agent'
import { formatFloat } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgencySelfCommissionStatisticList',
  components: {
    ShowCommissionStatistic,
    SearchAgencyCommissionStatistic,
    Pagination
  },
  data() {
    return {
      commissionTotal: 0.00,
      commissionAvailable: 0.00,
      data: [],
      loadingCommission: true,
      loadingTable: true,
      showingId: 0,
      showingBillableType: 0,
      isShowInfoModal: false,
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      return this.$store.getters.userAgentId
    },
    isMerchant() {
      return this.$store.getters.userRole === 'merchant'
    },
    columns() {
      return [
        {
          title: '时间',
          dataIndex: 'sort_time'
        },
        {
          title: '财务收支项',
          dataIndex: 'billable_type_display'
        },
        {
          title: '事件批次',
          scopedSlots: { customRender: 'billable_no' },
          dataIndex: 'billable_no'
        },
        {
          title: '账单金额(元)',
          customRender: formatFloat,
          dataIndex: 'total_fee'
        },
        {
          title: '佣金(元)',
          customRender: formatFloat,
          dataIndex: 'commission'
        }
      ]
    }
  },
  created() {
    this.fetchCommissionStatistic()
    this.fetchTableData()
  },
  methods: {
    showInfoModal(record) {
      this.showingId = record.id
      this.showingBillableType = record.billable_type_slug
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchTableData()
    },

    fetchCommissionStatistic() {
      this.loadingCommission = true
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.commissionTotal = res.data.commission_total
          this.commissionAvailable = res.data.commission_available
        }
        this.loadingCommission = false
      })
    },

    fetchTableData() {
      this.loadingTable = true
      findAgentCommissionStatistics(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loadingTable = false
      })
    },

    exportData() {
      exportAgentCommissionStatistics(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
